<template lang="pug">
  div
    server-time-clock
    current-class-application(
      :show-filter="false"
      :init="timeRange"
      :showPage="bookClassConstants.SHOW_PAGE.BOOK_CLASS"
    )
    current-class-application(:showPage="bookClassConstants.SHOW_PAGE.BOOK_CLASS_RECORD")
</template>

<script type="text/javascript">
import ServerTimeClock from '@/components/serverTimeClock';
import bookClassRecord from '@/components/vip/bookClassRecord';
import CurrentClassApplication from '@/components/vip/currentClassApplication';
import bookClassConstants from '@/constants/bookClass.js';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  name: 'Coupon',

  components: {
    bookClassRecord,
    CurrentClassApplication,
    ServerTimeClock,
  },

  data() {
    return {
      timeRange: null,
      firstLoad: true,
      bookClassConstants: bookClassConstants,
    };
  },
  computed: {
    ...mapState({
      currentTime: state => state.common.currentTime,
    }),
  },
  watch: {
    currentTime(){
      if (this.firstLoad){
        this.firstLoad = false;
        const start = moment(this.currentTime).format('YYYY-MM-DD');
        const end = moment(this.currentTime).add(31, 'day').format('YYYY-MM-DD');
        // 目前訂課參數
        this.timeRange = { start, end };
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/transition';
</style>
