const privateBookingClassRecordModalLang = {
  messages: {
    tw: {
      title_view: '您已經完成客製化課程需求的設定',
      title_1on1: '1on1 家教',
      ok: '確認',
      cancel: '取消',
      class_time: '上課時間',
      choice_consultant: '指定顧問',
      interested_topic: '適性分析',
      interested_materail: '適性教材',
      material: '教材',
      download: '點我下載',
      material_type: '教材類型',
      memo: '備註',
      select_consultant_here: '選擇顧問...',
      please_select: '請選擇',
      upload: '自行上傳',
      upload_file: '上傳檔案',
      upload_material: '上傳教材...',

    },
    en: {
      title_view: '',
      title_1on1: '1on1',
      ok: 'OK',
      cancel: 'Cancel',
      class_time: 'Class Time',
      choice_consultant: 'Choice Consultant',
      interested_topic: 'Interested Topic',
      interested_materail: 'Interested Materail',
      material: 'Material',
      download: 'Download',
      material_type: 'Material Type',
      memo: 'Memo',
      select_consultant_here: 'Select Consultant Here...',
      please_select: 'Please Select ',
      upload: 'Upload',
      upload_file: 'Upload File',
      upload_material: 'Upload Material...',
    },
  },
};

export {
  privateBookingClassRecordModalLang
};
