<template lang="pug">
div
  .wuwow-card
    .wuwow-card-head
      h4.wuwow-card-title 訂課紀錄
    .wuwow-card-body
      .row
        .col-12.col-md-6.col-lg-6.col-xl-3.are-clsapc-input
          .input-group
            //- TODO: CSS要調整(input如果資料只有一筆 展開的視窗會被改住)
            datepicker#filter_start_date(@input='onSearch' v-model='filter.start_date' :bootstrapstyling='true' format='yyyy-MM-dd' :disabled='startDateDisable' placeholder='開始時間')
        .col-12.col-md-6.col-lg-6.col-xl-3.are-clsapc-input
          .input-group
            datepicker#filter_end_date(@input='onSearch' v-model='filter.end_date' :bootstrapstyling='true' format='yyyy-MM-dd' :disabled='endDateDisable' placeholder='結束時間')
        .col-12.col-md-6.col-lg-6.col-xl-3.are-clsapc-input
          .input-group
            .input-group-prepend
              span.input-group-text 課程型態
            b-form-select#form-select(@input='onSearch' v-model='filter.class_type' :options='class_type_options')
        .col-12.col-md-6.col-lg-6.col-xl-3.are-clsapc-input
          .input-group
            .input-group-prepend
              span.input-group-text 狀態
            b-form-select#form-select(@input='onSearch' v-model='filter.class_deleted' :options='class_deleted')
      table.table.table-hover.table-clsapc-record
        thead.thead-default
          tr
            th 上課時間
            th 課程型態
            th 狀態
        tbody(v-if="classPastApplication.length !== 0")
          tr(v-for='item in classPastApplication' :key='item.application_id')
            td {{ item.class_time }}
            td
              a.lnk-clsapc-1on1(v-if='enable1on1Link(item)' href='javascript:;' @click='onOpenSetting(item)' v-b-modal.modalPopover)
                //- TODO: 沒有這個icon=>file-arrow-up
                font-awesome-icon(:icon="['fa', 'file']")
                |  {{ item.class_type_title }}
              template(v-else='')
                span.txt-clsapc-type {{ item.class_type_title }}
              template(v-if='item.option && item.option.consultant')
                font-awesome-icon(:icon="['fa', 'user']" class="ml-2")
            td {{ item.class_status }}
    .wuwow-card-footer
      b-pagination(align='center' :total-rows='classPastApplication.total' v-model='past_currentPage' :per-page='Number(classPastApplication.per_page)' @input='past_currentPage_change()')
  //- TODO: getOpenUpdateBooking 要從API get
  private-booking-class-record-modal(v-if="getOpenUpdateBooking" :key="getOpenUpdateBooking===true" @updateRecord="past_currentPage_change" :currentClassApplication="currentClassApplication")
</template>

<script type="text/javascript">
import { FullCalendar } from 'vue-full-calendar';
import CurrentClassApplication from '../currentClassApplication';
import SystemTime from '../../systemTime';
import 'fullcalendar/dist/fullcalendar.css';
import datepicker from 'vuejs-datepicker';
import moment from 'moment';
import privateBookingClassRecordModal from '@/components/vip/privateBookingClassRecordModal';
// updatePrivateBookingModal
export default {
  name: 'BookClass',

  components: {
    FullCalendar,
    CurrentClassApplication,
    SystemTime,
    datepicker,
    moment,
    privateBookingClassRecordModal,
  },

  // i18n: recommendeDrecordsLang,

  props: {
    classPastApplication: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },

  data() {
    return {
      filter: {
        start_date: '',
        end_date: '',
        class_type: 'all',
        class_deleted: 'all',
      },
      class_type_options: [
        { value: 'all', text: '全部' },
        { value: 1, text: '1on1線上課程' },
        { value: 2, text: '1 ~ 3 人微班級' },
        { value: 6, text: '百家書苑' },
      ],
      class_deleted: [
        { value: 'all', text: '全部' },
        { value: 'no_show', text: '未出席' },
        { value: 'show', text: '正常' },
        { value: 'deleted', text: '已刪除' },
        { value: 'return', text: '歸還課程點數' },
      ],
      next_currentPage: 1,
      past_currentPage: 1,

      // TODO: 先暫時放假資料
      getOpenUpdateBooking: false,
      currentClassApplication: {},
    };
  },

  computed: {
    startDateDisable: function() {
      if (this.filter.end_date !== '') {
        return {
          from: this.filter.end_date,
        };
      }
      return {};
    },
    endDateDisable: function() {
      if (this.filter.start_date !== '') {
        return {
          to: this.filter.start_date,
        };
      }
      return {};
    },
  },

  watch: {},

  created() {
  },

  methods: {
    onSearch() {
      this.past_currentPage = 1;
      this.past_currentPage_change();
    },
    past_currentPage_change() {
      const params = {};
      if (this.filter.start_date !== '') {
        params.cts = moment(this.filter.start_date).format('YYYY-MM-DD');
      }
      if (this.filter.end_date !== '') {
        params.cte = moment(this.filter.end_date).format('YYYY-MM-DD');
      }
      if (this.filter.class_type !== '' && this.filter.class_type !== 'all') {
        params.ct = this.filter.class_type;
      }
      if (this.filter.class_deleted !== '' && this.filter.class_deleted !== 'all') {
        params.vst = this.filter.class_deleted;
      }
      params.page = this.past_currentPage;
      params.st = 'class_record';
    },
    enable1on1Link(classApplication) {
      const settingLimit = moment(classApplication.class_time).subtract(72, 'hour');

      return classApplication.class_type_id === 1 &&
        (moment().isBefore(settingLimit) || classApplication.option) && (classApplication.class_deleted === 0 || classApplication.option);
    },
    onOpenSetting(classApplication) {
      // TODO: 這個function 下面要重寫欸
      this.getOpenUpdateBooking = true;

      // const operation = (classApplication.option) ? 'view' : 'update';
      const operation = (classApplication.option) ? 'view' : 'update';
      this.currentClassApplication = {
        ca_id: classApplication.application_id,
        date: moment(classApplication.class_time).format('YYYY-MM-DD'),
        class_time: moment(classApplication.class_time).format('HH:mm'),
        option: classApplication.option,
        operation: operation,
        data_resource: 'application',
        todayBooking: false,
      };
      // const currentClassApplication = {
      //   ca_id: classApplication.application_id,
      //   date: moment(classApplication.class_time).format('YYYY-MM-DD'),
      //   class_time: moment(classApplication.class_time).format('HH:mm'),
      //   option: classApplication.option,
      //   operation: operation,
      //   data_resource: 'application',
      //   todayBooking: false,
      // };
      // this.setCurrentClassApplication(currentClassApplication);
      // this.switchOpenUpdateBookingModal(true);
    },
  },
};
</script>
<style lang="scss">
@import '@/components/vip/bookClassRecord/bookClassRecord';
</style>
