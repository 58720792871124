<template lang="pug">
.booking-from
  b-alert.mt-3(v-if="consultantList.length==0 && this.booking_type != 'private'" variant='danger' show='')
    | 若指定顧問選單沒有顧問可選擇，表示該時段顧問已被預定，
    | 但該時段仍可訂課，教務部門將會另外為您安排「此主題專業且附經驗之顧問」進行課程
  div(:class="[booking_type === 'update' ? 'row' : '']")
    b-form-group(:class="[booking_type=='update'?'col-sm-6':'']" :horizontal="booking_type != 'update'" :label-cols='3' breakpoint='sm' :label="$t('choice_consultant')" label-for='ddlConsultant')
      multiselect#ddlConsultant(v-model='custom_application.consultant' track-by='consultant_id' :placeholder="$t('select_consultant_here')" :options='consultantList' label='english_name' :multiple='false' :clear-on-select='true' :close-on-select='true' :hide-selected='false' :preserve-search='true' :max-height='300' :disabled="booking_type=='private'")
      p.annotation(v-if='custom_application.consultant && custom_application.consultant.vip_like_consultant === 0') &#x300A;&#x6CE8;&#x610F;! &#x60A8;&#x6392;&#x9664;&#x6B64;&#x9867;&#x554F;&#x6388;&#x8AB2;&#xFF0C;&#x82E5;&#x78BA;&#x5B9A;&#x6307;&#x5B9A;&#x6B64;&#x9867;&#x554F;&#xFF0C;WUWOW&#x5C07;&#x4F9D;&#x60A8;&#x6307;&#x5B9A;&#x5B89;&#x6392;&#x3002;&#x300B;
    b-form-group(:class="[booking_type=='update'?'col-sm-6':'']" :horizontal="booking_type != 'update'" :label-cols='3' breakpoint='sm' :label="$t('material_type')" label-for='ddlMaterialType')
      b-form-select#ddlMaterialType(v-model='custom_application.material_type' :options='material_type_option')
  div(:class="[booking_type=='update'?'row':'']" v-if='custom_application.material_type == 1')
    b-form-group(:class="[booking_type=='update'?'col-sm-6':'']" :horizontal="booking_type != 'update'" :label-cols='3' breakpoint='sm' :label="$t('interested_topic')" label-for='ddlInterestedTopic')
      b-form-select#ddlInterestedTopic(v-model='custom_application.topic' :options='topicList' value-field='id' text-field='title' @change='getInterestedMaterail')
        template(slot='first')
          option(:value='null') {{ $t(&apos;please_select&apos;) + $t(&apos;interested_topic&apos;) }}
    b-form-group(:class="[booking_type=='update'?'col-sm-6':'']" :horizontal="booking_type != 'update'" v-if='showSelectMaterail' :label-cols='3' breakpoint='sm' :label="$t('interested_materail')" label-for='ddlInterestedMaterail')
      multiselect#ddlInterestedMaterail(v-model='custom_application.selectMaterial' :placeholder="$t('please_select') + $t('interested_materail')" track-by='title' :options='materailList' label='title_level' :multiple='false' :clear-on-select='true' :close-on-select='true' :hide-selected='false' :preserve-search='true')
    b-form-group(:class="[booking_type=='update'?'col-sm-6':'']" :horizontal="booking_type != 'update'")
      b-alert(v-if='checkExamTopic' variant='danger' show='')
        | 因考試類型教材，需由WUWOW依照考試類別安排「專業且附經驗之顧問」進行課程，故無法在指定顧問頁面選擇考試類型教材。若有此需求，請至訂課頁面選擇一對一課程，並選擇考試適性分析喲!
        br
        | 考試類型教材為練習題型，建議考前一至兩個月開始安排，也請在訂課時備註您的考試日期
  div(:class="[booking_type=='update'?'row':'']" v-else-if='custom_application.material_type != 1')
    b-form-group(:class="[booking_type=='update'?'col-sm-6':'']" :horizontal="booking_type != 'update'" :label-cols='3' breakpoint='sm' :label="$t('upload_file')" label-for='blk-clsapc-file')
      .blk-clsapc-file
        b-alert(variant='warning' show='') 僅接受格式為"ppt, pttx"且大小5MB以下的檔案，請務必上傳英文內容之教材，並避免敏感及爭議性話題。
        file-upload(v-if="booking_type!='update'" ref='materialUpload' :select-text="$t('upload_material')" accept='.ppt,.pptx' :files='custom_application.material' :extensions="['ppt','pptx']" @select='afterSelectMaterial' @extensionerror='extensionError')
    div(:class="[booking_type=='update'?'col-sm-6':'']")
      p.txt-material-info.text-center(v-if='custom_application.material.length > 0')
        br
        | {{ custom_application.material[0].name }}
      p.txt-material-error.text-center(v-else-if="fileErrorMessage != ''") 上傳檔案格式不符，請重新選擇PowerPoint文件，謝謝。
      file-upload.mt-3(ref='materialUpload' v-if="booking_type=='update'" :select-text="$t('upload_material')" accept='.ppt,.pptx' :files='custom_application.material' :extensions="['ppt','pptx']" @select='afterSelectMaterial' @extensionerror='extensionError')
        .ui-are-fileUpload
          .ui-blk-fileUpload
            button(type="button" class="btn btn-primary ui-btn-fileUpload") Select File...
            input(ref="fileupload" type="file" class="ui-ipt-fileUpload" :multiple="multiple" :accept="accept" @change="onFileChange")
  b-form-group(:horizontal="booking_type != 'update'" :label-cols='12' breakpoint='sm' :label="$t('memo')" label-for='txtClassMemo')
    b-form-textarea#txtClassMemo(v-model='custom_application.memo' type='text' rows='5' placeholder='如果您想安排更特定的教材主題，例如: 面試、發音，可在此處留言。如需較專業或學術的英文教材，因無法適用於其它學員，請自行上傳提供。若無符合您需求的教材，教務部門也會與您聯繫討論。')
  div
    p.annotation 《註：若指定之顧問因不可抗因素無法授課，教務團隊將依照您的喜好顧問列表為你更換顧問，並即時通知您。》
</template>

<script type="text/javascript">
import { privateBookingClassRecordModalLang } from './lang';
import VueMultiselect from 'vue-multiselect';
import moment from 'moment';

export default {

  name: 'UpdatePrivateBookingForm',

  components: {
    multiselect: VueMultiselect,
    moment,
  },

  props: {
    booking_type: {
      type: String,
      default: function() {
        return '';
      },
    },
  },

  data() {
    return {
      custom_application: {
        consultant: null,
        material_type: 1,
        topic: null,
        material: [],
        memo: '',
        selectMaterial: '',
      },
      // consultantList: [],

      origin_consultant: null,

      material_type_option: [
        { value: 1, text: this.$t('interested_topic') },
        { value: 2, text: this.$t('upload') },
      ],

      topicList: [],

      materailList: [],

      fileErrorMessage: '',

      checkExamTopic: false,

      showSelectMaterail: true,

      // 假資料先放著
      consultantList: [
        {
          consultant_id: 1576,
          chinese_name: '',
          english_name: 'Ann Salve',
          vip_like_consultant: null,
        },
        {
          consultant_id: 2089,
          chinese_name: null,
          english_name: 'Esmeralda Rabeli',
          vip_like_consultant: null,
        },
        {
          consultant_id: 1478,
          chinese_name: 'a',
          english_name: 'Hope Guinto Montebon',
          vip_like_consultant: null,
        },
        {
          consultant_id: 1277,
          chinese_name: 'b',
          english_name: 'Raymark Salvarita',
          vip_like_consultant: null,
        },
        {
          consultant_id: 1425,
          chinese_name: 'c',
          english_name: 'Ronelyn Dagohoy Lizare',
          vip_like_consultant: null,
        },
      ],
    };
  },

  computed: {
  },

  watch: {},

  mounted() {
  },

  i18n: privateBookingClassRecordModalLang,

  created() {
  },

  methods: {
    setConsultant(){
      if (this.booking_type !== 'private') {
        this.getAvailableConsultant();
      } else {
        this.custom_application.consultant = this.consultant;
      }
    },

    getAvailableConsultant() {
      // let class_time;
      // if (this.bookTime === '24:00') {
      //   class_time = moment(this.bookDay).add(1, 'day').format('YYYY-MM-DD') + ' ' + '00:00:00';
      // } else {
      //   class_time = this.bookDay + ' ' + this.bookTime + ':00';
      // }
      // const testType = this.getTestTopicConsultant();

      // const params = {
      //   cts: class_time,
      //   st: 'vip_apply_consultant',
      //   sort: 'english_name|asc',
      //   ...testType,
      // };

      // 先註解 要再引用axios
      // axios.get(process.env.API_BASE_URL + 'consultant/available', {
      //   params
      // }).then((response) => {
      //   this.consultantList = response.data.data;
      // }).catch((error) => {
      //   console.error(error);
      // });
    },

    getTestTopicConsultant(){
      if (this.custom_application.topic === 16 || this.custom_application.topic === 25) { // 多益閱讀或聽力
        return { itc: 1 };
      } else if (this.custom_application.topic === 15) { // 托福
        return { itf: 1 };
      } else if (this.custom_application.topic === 17) { // 雅思
        return { iis: 1 };
      } else {
        return '';
      }
    },

    getInterestedTopic() {
      // let params = {};
      // if(this.booking_type === "private") {
      //   params = { consultant_id: this.consultant.consultant_id, }
      // }
      // 先註解 要再引用axios
      // axios.get(process.env.API_BASE_URL + 'suitable-analysis/interested_topic',{params}).then((response) => {
      //   this.filterInterestedTopicList(response.data.data);
      // }).catch((error) => {
      //   console.error(error);
      // });
    },

    filterInterestedTopicList(data) {
      if (this.UserIconData.level >= 7) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].id === 7) {
            data.splice(i, 1);
            this.topicList = data;
          }
        }
      } else {
        this.topicList = data;
      }
    },

    getInterestedMaterail(it) {
      this.custom_application.selectMaterial = null;
      if (it === 16 || it === 17 || it === 25) {
        // 25為多益聽力 17為雅思 16為多益閱讀不顯示適性分析
        this.showSelectMaterail = false;
      } else {
        this.showSelectMaterail = true;
        // axios.get(process.env.API_BASE_URL + 'class/interested-topic/material',{
        //   params: {
        //     it: it,
        //   }
        // }).then((response) => {
        //   this.materailList = response.data;
        // }).catch((error) => {
        //   console.error(error);
        // });
      }
    },

    afterSelectMaterial(file) {
      this.fileErrorMessage = '';
    },

    extensionError(file) {
      this.fileErrorMessage = '上傳檔案格式不符，請重新選擇PowerPoint文件，謝謝。';
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
  @import './privateBookingClassRecordModal.scss';
</style>
