<template lang="pug">
  div
    //- getOpenUpdateBooking
    //-  :visible='getOpenUpdateBooking' title="您已經完成客製化課程需求的設定"
    b-modal(id="modalPopover" centered size='lg' :title="titleContent" :ok-title="$t('ok')" :ok-only="currentClassApplication.operation == 'view'" :cancel-title="$t('cancel')" body-class='are-1on1-dialog' footer-class='justify-content-center' @ok.prevent='onSubmit' :ok-disabled='!enableSubmit' @hidden='closeUpdate')
      div {{ currentClassApplication }}
      //- h3.txt-1on1-desc(v-if="currentClassApplication.operation == 'update'")
      //-   | {{ currentClassApplication.date + ' ' + currentClassApplication.class_time }} 1on1 家教;
      //- h3.txt-1on1-desc(v-else) 您已經完成客製化課程需求的設定。
      template(v-if="currentClassApplication.operation == 'view'")
        update-private-booking-form(:booking_type="'update'" :bookday='currentClassApplication.date' :booktime='currentClassApplication.class_time' @custom-application='updateApplicationData')
      template(v-else)
        .viewBox
          .row
            div
              span {{ $t('class_time') }}:
              b-badge(variant='danger') {{ currentClassApplication.date + ' ' + currentClassApplication.class_time }}
          .row
            div
              span {{ $t('choice_consultant') }}:
              template(v-if='currentClassApplication.option.consultant')
                b-badge(variant='success') {{ currentClassApplication.option.consultant }}
              template(v-else)
                b-badge(variant='secondary' style='color:white;') 未指定
          .row(v-if='currentClassApplication.option.suitable_id')
            div
              span {{ $t('interested_topic') }}:
              b-badge(variant='primary') {{ currentClassApplication.option.suitable_id }}
          // 素材名稱
          .row(v-if='currentClassApplication.option.suitable_id')
            div
              span {{ $t('interested_materail') }}:
              template(v-if='currentClassApplication.option.material_title')
                | {{ currentClassApplication.option.material_title }}
              template(v-else) 未指定
          .row(v-else-if='currentClassApplication.option.material_id')
            div
              span {{ $t('material') }}:
              a(:href='currentClassApplication.option.material_id') {{ $t('download') }}
          .row(v-else)
            div
              span {{ $t('material_type') }}:未指定
          .row
            div
              span {{ $t('memo') }}:{{ currentClassApplication.option.memo }}
        hr

</template>

<script type="text/javascript">
import { privateBookingClassRecordModalLang } from './lang';
import updatePrivateBookingForm from './updatePrivateBookingForm.vue';

export default {

  name: 'PrivateBookingClassRecordModal',

  components: {
    updatePrivateBookingForm,
  },

  props: {
    currentClassApplication: Object,
  },

  data() {
    return {
      titleContent: 'title_view',
    };
  },

  computed: {
    enableSubmit() {
      return true;
      // 無法送出條件
      // TODO: function內部要打開
      // if(this.currentClassApplication.operation == 'view'){
      //   return true;
      // }else{
      //   return this.disableSubmit;
      // }
    },
  },

  watch: {},

  mounted() {
  },

  i18n: privateBookingClassRecordModalLang,

  created() {
    this.setModalTitle();
  },

  methods: {
    closeUpdate() {
      // TODO: function內部要打開
      if (this.currentClassApplication.todayBooking === true){
        // 從當日訂課更新課程才需要打開booking modal
        this.switchOpenBookingModal(true);
        this.focusOnTodayBook(true);
      }
      this.switchOpenUpdateBookingModal(false);
    },

    setModalTitle() {
      // 為區分有沒有設定過1on1資訊的modal title
      // TODO: 要確認會不會因為切換modal標題會變動
      const classData = this.currentClassApplication;
      const title_1on1 = classData.date + ' ' + classData.class_time + ' ' + this.$t('title_1on1');
      const title_view = this.$t('title_view');
      // TODO: 應該是===
      this.titleContent = (classData.operation !== 'view') ? title_view : title_1on1;
    },

    updateApplicationData: function(option){
      this.disableSubmit = this.canSubmit(option);
      if (option.consultant) {
        this.classOptions.consultant_id = option.consultant.consultant_id;
      } else {
        delete this.classOptions.consultant_id;
      }
      if (option.isUploadMaterial === 1) {
        this.classOptions.material_file = option.materialFile;
      } else {
        delete this.classOptions.material_file;
      }
      this.classOptions.memo = option.memo;
    },

  },
};
</script>

<style lang="scss">
  @import './privateBookingClassRecordModal.scss';
</style>
