var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "booking-from"
  }, [_vm.consultantList.length == 0 && this.booking_type != 'private' ? _c('b-alert', {
    staticClass: "mt-3",
    attrs: {
      "variant": "danger",
      "show": ""
    }
  }, [_vm._v("若指定顧問選單沒有顧問可選擇，表示該時段顧問已被預定， 但該時段仍可訂課，教務部門將會另外為您安排「此主題專業且附經驗之顧問」進行課程")]) : _vm._e(), _c('div', {
    class: [_vm.booking_type === 'update' ? 'row' : '']
  }, [_c('b-form-group', {
    class: [_vm.booking_type == 'update' ? 'col-sm-6' : ''],
    attrs: {
      "horizontal": _vm.booking_type != 'update',
      "label-cols": 3,
      "breakpoint": "sm",
      "label": _vm.$t('choice_consultant'),
      "label-for": "ddlConsultant"
    }
  }, [_c('multiselect', {
    attrs: {
      "id": "ddlConsultant",
      "track-by": "consultant_id",
      "placeholder": _vm.$t('select_consultant_here'),
      "options": _vm.consultantList,
      "label": "english_name",
      "multiple": false,
      "clear-on-select": true,
      "close-on-select": true,
      "hide-selected": false,
      "preserve-search": true,
      "max-height": 300,
      "disabled": _vm.booking_type == 'private'
    },
    model: {
      value: _vm.custom_application.consultant,
      callback: function ($$v) {
        _vm.$set(_vm.custom_application, "consultant", $$v);
      },
      expression: "custom_application.consultant"
    }
  }), _vm.custom_application.consultant && _vm.custom_application.consultant.vip_like_consultant === 0 ? _c('p', {
    staticClass: "annotation"
  }, [_vm._v("《注意! 您排除此顧問授課，若確定指定此顧問，WUWOW將依您指定安排。》")]) : _vm._e()], 1), _c('b-form-group', {
    class: [_vm.booking_type == 'update' ? 'col-sm-6' : ''],
    attrs: {
      "horizontal": _vm.booking_type != 'update',
      "label-cols": 3,
      "breakpoint": "sm",
      "label": _vm.$t('material_type'),
      "label-for": "ddlMaterialType"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "ddlMaterialType",
      "options": _vm.material_type_option
    },
    model: {
      value: _vm.custom_application.material_type,
      callback: function ($$v) {
        _vm.$set(_vm.custom_application, "material_type", $$v);
      },
      expression: "custom_application.material_type"
    }
  })], 1)], 1), _vm.custom_application.material_type == 1 ? _c('div', {
    class: [_vm.booking_type == 'update' ? 'row' : '']
  }, [_c('b-form-group', {
    class: [_vm.booking_type == 'update' ? 'col-sm-6' : ''],
    attrs: {
      "horizontal": _vm.booking_type != 'update',
      "label-cols": 3,
      "breakpoint": "sm",
      "label": _vm.$t('interested_topic'),
      "label-for": "ddlInterestedTopic"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "ddlInterestedTopic",
      "options": _vm.topicList,
      "value-field": "id",
      "text-field": "title"
    },
    on: {
      "change": _vm.getInterestedMaterail
    },
    model: {
      value: _vm.custom_application.topic,
      callback: function ($$v) {
        _vm.$set(_vm.custom_application, "topic", $$v);
      },
      expression: "custom_application.topic"
    }
  }, [_c('template', {
    slot: "first"
  }, [_c('option', {
    domProps: {
      "value": null
    }
  }, [_vm._v(_vm._s(_vm.$t('please_select') + _vm.$t('interested_topic')))])])], 2)], 1), _vm.showSelectMaterail ? _c('b-form-group', {
    class: [_vm.booking_type == 'update' ? 'col-sm-6' : ''],
    attrs: {
      "horizontal": _vm.booking_type != 'update',
      "label-cols": 3,
      "breakpoint": "sm",
      "label": _vm.$t('interested_materail'),
      "label-for": "ddlInterestedMaterail"
    }
  }, [_c('multiselect', {
    attrs: {
      "id": "ddlInterestedMaterail",
      "placeholder": _vm.$t('please_select') + _vm.$t('interested_materail'),
      "track-by": "title",
      "options": _vm.materailList,
      "label": "title_level",
      "multiple": false,
      "clear-on-select": true,
      "close-on-select": true,
      "hide-selected": false,
      "preserve-search": true
    },
    model: {
      value: _vm.custom_application.selectMaterial,
      callback: function ($$v) {
        _vm.$set(_vm.custom_application, "selectMaterial", $$v);
      },
      expression: "custom_application.selectMaterial"
    }
  })], 1) : _vm._e(), _c('b-form-group', {
    class: [_vm.booking_type == 'update' ? 'col-sm-6' : ''],
    attrs: {
      "horizontal": _vm.booking_type != 'update'
    }
  }, [_vm.checkExamTopic ? _c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": ""
    }
  }, [_vm._v("因考試類型教材，需由WUWOW依照考試類別安排「專業且附經驗之顧問」進行課程，故無法在指定顧問頁面選擇考試類型教材。若有此需求，請至訂課頁面選擇一對一課程，並選擇考試適性分析喲!"), _c('br'), _vm._v("考試類型教材為練習題型，建議考前一至兩個月開始安排，也請在訂課時備註您的考試日期")]) : _vm._e()], 1)], 1) : _vm.custom_application.material_type != 1 ? _c('div', {
    class: [_vm.booking_type == 'update' ? 'row' : '']
  }, [_c('b-form-group', {
    class: [_vm.booking_type == 'update' ? 'col-sm-6' : ''],
    attrs: {
      "horizontal": _vm.booking_type != 'update',
      "label-cols": 3,
      "breakpoint": "sm",
      "label": _vm.$t('upload_file'),
      "label-for": "blk-clsapc-file"
    }
  }, [_c('div', {
    staticClass: "blk-clsapc-file"
  }, [_c('b-alert', {
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_vm._v("僅接受格式為\"ppt, pttx\"且大小5MB以下的檔案，請務必上傳英文內容之教材，並避免敏感及爭議性話題。")]), _vm.booking_type != 'update' ? _c('file-upload', {
    ref: "materialUpload",
    attrs: {
      "select-text": _vm.$t('upload_material'),
      "accept": ".ppt,.pptx",
      "files": _vm.custom_application.material,
      "extensions": ['ppt', 'pptx']
    },
    on: {
      "select": _vm.afterSelectMaterial,
      "extensionerror": _vm.extensionError
    }
  }) : _vm._e()], 1)]), _c('div', {
    class: [_vm.booking_type == 'update' ? 'col-sm-6' : '']
  }, [_vm.custom_application.material.length > 0 ? _c('p', {
    staticClass: "txt-material-info text-center"
  }, [_c('br'), _vm._v(_vm._s(_vm.custom_application.material[0].name))]) : _vm.fileErrorMessage != '' ? _c('p', {
    staticClass: "txt-material-error text-center"
  }, [_vm._v("上傳檔案格式不符，請重新選擇PowerPoint文件，謝謝。")]) : _vm._e(), _vm.booking_type == 'update' ? _c('file-upload', {
    ref: "materialUpload",
    staticClass: "mt-3",
    attrs: {
      "select-text": _vm.$t('upload_material'),
      "accept": ".ppt,.pptx",
      "files": _vm.custom_application.material,
      "extensions": ['ppt', 'pptx']
    },
    on: {
      "select": _vm.afterSelectMaterial,
      "extensionerror": _vm.extensionError
    }
  }, [_c('div', {
    staticClass: "ui-are-fileUpload"
  }, [_c('div', {
    staticClass: "ui-blk-fileUpload"
  }, [_c('button', {
    staticClass: "btn btn-primary ui-btn-fileUpload",
    attrs: {
      "type": "button"
    }
  }, [_vm._v("Select File...")]), _c('input', {
    ref: "fileupload",
    staticClass: "ui-ipt-fileUpload",
    attrs: {
      "type": "file",
      "multiple": _vm.multiple,
      "accept": _vm.accept
    },
    on: {
      "change": _vm.onFileChange
    }
  })])])]) : _vm._e()], 1)], 1) : _vm._e(), _c('b-form-group', {
    attrs: {
      "horizontal": _vm.booking_type != 'update',
      "label-cols": 12,
      "breakpoint": "sm",
      "label": _vm.$t('memo'),
      "label-for": "txtClassMemo"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "txtClassMemo",
      "type": "text",
      "rows": "5",
      "placeholder": "如果您想安排更特定的教材主題，例如: 面試、發音，可在此處留言。如需較專業或學術的英文教材，因無法適用於其它學員，請自行上傳提供。若無符合您需求的教材，教務部門也會與您聯繫討論。"
    },
    model: {
      value: _vm.custom_application.memo,
      callback: function ($$v) {
        _vm.$set(_vm.custom_application, "memo", $$v);
      },
      expression: "custom_application.memo"
    }
  })], 1), _vm._m(0)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('p', {
    staticClass: "annotation"
  }, [_vm._v("《註：若指定之顧問因不可抗因素無法授課，教務團隊將依照您的喜好顧問列表為你更換顧問，並即時通知您。》")])]);

}]

export { render, staticRenderFns }