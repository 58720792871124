<template lang="pug">
  div
    p.txt-system-time
      font-awesome-icon.clock-icon(:icon="['far', 'clock']" class="fa-2x")
      | &nbsp;
      | {{ now }}
      br
</template>

<script type="text/javascript">
export default {
  name: 'SystemTime',

  components: {
  },

  data() {
    return {
      now: '2022-01-05 12:00:00',
    };
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
  },
};
</script>
