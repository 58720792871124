var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "modalPopover",
      "centered": "",
      "size": "lg",
      "title": _vm.titleContent,
      "ok-title": _vm.$t('ok'),
      "ok-only": _vm.currentClassApplication.operation == 'view',
      "cancel-title": _vm.$t('cancel'),
      "body-class": "are-1on1-dialog",
      "footer-class": "justify-content-center",
      "ok-disabled": !_vm.enableSubmit
    },
    on: {
      "ok": function ($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      },
      "hidden": _vm.closeUpdate
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.currentClassApplication))]), _vm.currentClassApplication.operation == 'view' ? [_c('update-private-booking-form', {
    attrs: {
      "booking_type": 'update',
      "bookday": _vm.currentClassApplication.date,
      "booktime": _vm.currentClassApplication.class_time
    },
    on: {
      "custom-application": _vm.updateApplicationData
    }
  })] : [_c('div', {
    staticClass: "viewBox"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.$t('class_time')) + ":")]), _c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.currentClassApplication.date + ' ' + _vm.currentClassApplication.class_time))])], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.$t('choice_consultant')) + ":")]), _vm.currentClassApplication.option.consultant ? [_c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(_vm._s(_vm.currentClassApplication.option.consultant))])] : [_c('b-badge', {
    staticStyle: {
      "color": "white"
    },
    attrs: {
      "variant": "secondary"
    }
  }, [_vm._v("未指定")])]], 2)]), _vm.currentClassApplication.option.suitable_id ? _c('div', {
    staticClass: "row"
  }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.$t('interested_topic')) + ":")]), _c('b-badge', {
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(_vm._s(_vm.currentClassApplication.option.suitable_id))])], 1)]) : _vm._e(), _vm.currentClassApplication.option.suitable_id ? _c('div', {
    staticClass: "row"
  }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.$t('interested_materail')) + ":")]), _vm.currentClassApplication.option.material_title ? [_vm._v(_vm._s(_vm.currentClassApplication.option.material_title))] : [_vm._v("未指定")]], 2)]) : _vm.currentClassApplication.option.material_id ? _c('div', {
    staticClass: "row"
  }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.$t('material')) + ":")]), _c('a', {
    attrs: {
      "href": _vm.currentClassApplication.option.material_id
    }
  }, [_vm._v(_vm._s(_vm.$t('download')))])])]) : _c('div', {
    staticClass: "row"
  }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.$t('material_type')) + ":未指定")])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.$t('memo')) + ":" + _vm._s(_vm.currentClassApplication.option.memo))])])])]), _c('hr')]], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }